<template>
  <div class="ma-10" v-if="user">
    <h2>
      {{ this.$route.params.id ? 'Edit' : 'Create' }} Card - UserId({{
        user.uid
      }})
    </h2>
    <v-row class="ma-5" v-if="cardJson">
      <v-text-field label="Name" v-model="cardJson.name" :rules="nameRules" />
      <v-text-field
        class="mx-5"
        label="Email"
        v-model="cardJson.emails[0]"
        :rules="emailRules"
      />
      <v-text-field
        v-if="cardJson.phoneNumbers && cardJson.phoneNumbers.length > 0"
        class="mx-5"
        label="Phone Number"
        v-model="cardJson.phoneNumbers[0].phone"
        :rules="emailRules"
      />
      <v-text-field
        label="Designation"
        v-model="cardJson.designation"
        :rules="designationRules"
      />
      <template v-if="!this.$route.params.id">
        <v-checkbox
          class="mx-5"
          v-model="cardJson.enableChat"
          label="Enable Chat"
        ></v-checkbox>
        <v-switch
          v-model="cardJson.isPrivate"
          :label="cardJson.isPrivate ? 'Private Card' : 'Open Card'"
        ></v-switch>
      </template>
    </v-row>
    <card-draggable
      v-if="cardJson"
      class="my-10"
      :cardData="cardJson"
      :user="user"
      :backgroundCardImage="backgroundCardImage"
      :logoCardImage="logoCardImage"
      :customFont="customFont"
      @inputClickFileSelector="inputClickFileSelector"
    />
    <v-progress-circular
      v-if="loading"
      class="my-10"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-btn v-else color="primary" @click="createCard">{{
      this.$route.params.id ? 'Update' : 'Submit'
    }}</v-btn>
  </div>
</template>

<script>
import CardDraggable from '../components/CardDraggable.vue';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import firebase from 'firebase/app';
const htmlToImage = require('html-to-image');

export default {
  name: 'CreateCard',
  components: { CardDraggable },
  data() {
    return {
      cardJson: null,
      user: null,
      cardId: null,
      userId: null,
      backgroundCardImage: null,
      logoCardImage: null,
      customFont: null,
      loading: false,
      company: null,
      nameRules: [(v) => !!v || 'Name is required'],
      emailRules: [
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            (pattern.test(value) &&
              value.includes(`@${this.company.emailFormat}.`)) ||
            'Invalid Company e-mail.'
          );
        },
      ],
      designationRules: [(v) => !!v || 'Designation is required'],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
      hradmin: (state) => state.hradmin,
    }),
    ...mapGetters(['getRole']),
  },
  mounted() {
    if (this.getRole?.id != this.hradmin) {
      this.$router.push('/');
      return;
    }
  },
  async created() {
    const data = this.$route.params.data;
    let cardData;

    if (data?.userId) {
      console.log(data.userId);
      this.userId = data.userId;
    }

    if (data?.cardData) {
      console.log(data.userId);
      cardData = data.cardData;
    }

    let elementDesigns;

    if (this.$route.params.id) {
      this.cardId = this.$route.params.id;

      if (!cardData) {
        cardData = await this.fetchCard(this.cardId);

        elementDesigns = {
          ...cardData.elementDesigns,
        };
      }

      this.userId = cardData?.userId;
      this.user = await this.fetchUser(this.userId);
    } else {
      this.user = await this.fetchUser(this.userId);

      cardData = {
        name: this.user?.name || '',
        designation: this.user?.designation || '',
        userId: this.user.uid,

        qualification: '',
        category: '',
        deleted: false,

        isPrivate: true,
        enableChat: true,

        createDate: new Date().getTime(),
        updateDate: new Date().getTime(),

        screenshotPath: '',
        createdBy: this.currentUser.uid,
      };
    }

    if (this.cardId && cardData?.companyId != this.currentUser.companyId) {
      alert('this card id is not matched with your company');
      return;
    }

    if (!this.user) {
      return;
    }

    this.company = await this.fetchCompany(this.currentUser.companyId);

    if (!this.company) {
      return;
    }

    cardData.address = this.company.address;
    cardData.businessHours = this.company.businessHours;
    cardData.company = this.company.cardCompanyName;
    cardData.design = this.company.design;
    if (elementDesigns && !this.company.applyElementPosition) {
      const mixDesign = {};
      Object.keys(this.company.elementDesigns).forEach((key) => {
        mixDesign[key] = {
          ...this.company.elementDesigns[key],
          left: elementDesigns[key].left,
          right: elementDesigns[key].right,
          top: elementDesigns[key].top,
          bottom: elementDesigns[key].bottom,
        };
      });
      cardData.elementDesigns = mixDesign;
    } else {
      cardData.elementDesigns = this.company.elementDesigns;
    }
    cardData.isoCode =
      this.company?.phoneNumbers?.length > 0
        ? this.company.phoneNumbers[0].isoCode
        : 'IN';
    cardData.phoneNumbers =
      this.user.phone && this.user.countryCode
        ? [
            {
              countryCode: this.user.countryCode,
              phone: this.user.phone,
              type: 'Mobile',
              ext: '',
              isoCode: this.user.isoCode || cardData.isoCode,
              country: '',
            },
            ...this.company.phoneNumbers,
          ]
        : this.company.phoneNumbers;
    cardData.phoneNumbers = cardData.phoneNumbers
      ? cardData.phoneNumbers.filter((e) => e.phone && e.countryCode)
      : [];
    cardData.emails = this.user.email
      ? [this.user?.email || [], ...(this.company?.emails || [])]
      : this.company?.emails || [];
    cardData.emails = cardData.emails.filter((e) => e);
    cardData.tags = this.company.tags;
    cardData.moreInfo = this.company.moreInfo;
    cardData.socialLinks = this.company.socialLinks;
    cardData.website = this.company.website;
    cardData.companyId = this.company.id;

    setTimeout(() => {
      this.applyCustomFont(null, {
        name: this.company.design?.fontName || '',
        url: this.company.design?.fontUrl || '',
      });
    }, 500);

    this.cardJson = cardData;
  },
  methods: {
    ...mapActions(['fetchCompany', 'fetchUser', 'fetchCard', 'addCard']),
    ...mapMutations(['setSelectedNode']),
    async createCard() {
      console.log(this.cardJson);

      this.setSelectedNode(null);

      // htmlToImage
      //   .toBlob(document.getElementById('card-screenshot'))
      //   .then((dataUrl) => {
      //     console.log(dataUrl);
      //     let a = document.createElement('a');
      //     a.href = window.URL.createObjectURL(dataUrl);
      //     a.target = '_blank';
      //     a.click();
      //   });

      // return;

      this.loading = true;

      const cardId = await this.addCard({
        id: this.cardId,
        cardData: this.cardJson,
      });
      if (cardId) {
        this.cardId = cardId;
        this.cardJson.id = cardId;
        const blobFile = await htmlToImage.toBlob(
          document.getElementById('card-screenshot')
        );

        if (!blobFile) {
          this.loading = false;
          return;
        }

        const folder = `screenshot`;
        const fileName = `${this.cardJson.userId}-${this.cardJson.id}.png`;

        const storageRef = firebase
          .storage()
          .ref(`${folder}/${fileName}`)
          .put(blobFile);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            console.log(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              console.log(cardId, url);
              this.cardJson.screenshotPath = url;
              this.addCard({ id: cardId, cardData: this.cardJson });
              this.loading = false;
              this.$router.go(-1);
              alert(
                `${
                  this.$route.params.id
                    ? `card - ${cardId} updated successfully`
                    : `card - ${cardId} created successfully`
                }.`
              );
            });
          }
        );
      }
    },
    inputClickFileSelector(event, type) {
      console.log(event);
      const files = event?.target?.files;
      const data = files?.length
        ? {
            file: files.item(0),
            previewUrl: URL.createObjectURL(files.item(0)),
            uploadValue: 0,
          }
        : null;
      if (type == 'bg') {
        if (!this.backgroundCardImage && !data) {
          this.cardJson.design.bgUrl = '';
        }
        this.backgroundCardImage = data;
        this.cardJson.design.overlayIntensity = 0.0;
      } else if (type == 'logo') {
        if (!this.logoCardImage && !data) {
          this.cardJson.design.logoUrl = '';
        }
        this.logoCardImage = data;
      } else if (type == 'font') {
        if (!this.customFont && !data) {
          this.cardJson.design.fontUrl = '';
          this.cardJson.design.fontName = '';
        }

        console.log(data);
        this.customFont = data;
        this.applyCustomFont(data, null);
      }
      console.log(files);
      if (event) event.target.value = null;
    },
    applyCustomFont(data, urlData) {
      // upload
      // response of font path
      // create style tag
      let fileName = '';
      let url = '';
      if (data) {
        fileName = data.file.name;
        url = data.previewUrl;
      } else if (urlData) {
        fileName = urlData.name;
        url = urlData.url;
      }

      console.log(fileName, url);

      if (fileName && url) {
        const customFontEl = document.getElementById('custom-font');
        if (customFontEl) {
          // truthy: non-empty string, true, {}, [], >0
          // false: 0, null, undefine, ''
          customFontEl.innerHTML = `
              @font-face {
              font-family: '${fileName}';
              src: url(${url});
            }
            `;
        } else {
          const el = document.createElement('style');
          el.id = 'custom-font';
          el.innerHTML = `
              @font-face {
                font-family: '${fileName}';
                src: url(${url});
              }
              `;
          document.head.appendChild(el);
        }
        if (document.getElementById('draggable-container')) {
          document.getElementById(
            'draggable-container'
          ).style.fontFamily = `'${fileName}'`;
        }
      }
    },
  },
};
</script>

<style></style>
